/* eslint-disable indent */
import { paginateArray } from '@/libs/filter-data'
import {
  reducer, fechaHoraCortas, parserPedidos, parserRutas, consolear,
} from '@/libs/utils-pedidos'
import { directions, places, geo } from '@/libs/google-directions'
import { ref, computed } from '@vue/composition-api'
import store from '@/store'
import { useRouter } from '@core/utils/utils'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const DIRALMACEN = 'Carrer de Pere IV, 520, Barcelona'

export default function usePedidos() {
  // const consolear = loQueSea => {
  //   const isDev = process.env?.NODE_ENV === 'development'
  //   if (isDev) console.log(loQueSea)
  // }
  // Use toast
  const toast = useToast()
  // Use route
  const { route } = useRouter()
  // ------------------------------------------------
  // UI
  // ------------------------------------------------
  const isAddNewPedidoSidebarActive = ref(false)
  const isEtiquetasSelectionInit = ref(false)
  const isPickingPedido = ref(false)
  const isEditPedido = ref(false)
  const isPickingVehiculo = ref(false)
  const isPickingPedidosToRutas = ref(false)
  const pedidoSelect = ref({})
  const isAddNewClienteSidebarActive = ref(false)
  const isAddNewRutaSidebarActive = ref(false)
  const calculandoRuta = ref(false)
  const mapView = ref([])
  const itemsMostrarOrdenSync = ref(null)
  // Edición de ruta
  const rutaSelect = ref({})
  const isEditRuta = ref(false)
  // ------------------------------------------------
  // DATOS: Productos - Clientes - Pedidos - Rutas - Vehículos
  // ------------------------------------------------
  const productos = computed(() => store.state.productos.productos)
  const clientes = computed(() => store.state.clientes.clientes)
  const pedidos = computed(() => store.state.pedidos.pedidosPicking)
  const pedidosPicking = computed(() => {
    // Lista los pedidos pendientes de picking y no descartados
    const datos = store.state.pedidos.pedidosPicking
    return datos.filter(d => !d.is_descartado && !d.is_picking_completed)
  })
  const pedidosControl = computed(() => store.state.pedidos.pedidosHoyControl)
  const rutas = computed(() => store.state.rutas.rutas)
  const vehiculos = computed(() => store.state.vehiculos.vehiculos)

  const fetchProductos = () => store.dispatch('productos/getProductos')
  const fetchClientes = () => store.dispatch('clientes/getClientes')
  const fetchPedidos = () => store.dispatch('pedidos/getPedidosPicking')
  const fetchVehiculos = () => store.dispatch('vehiculos/getVehiculos')
  const fetchRutas = () => store.dispatch('rutas/getRutas')

  const pedidosPreOrdenados = ref([])
  const pedidosOrdenAnterior = ref([])
  const overviewPath = ref([])
  const pedidosSelected = ref([])
  const rutasSelecOptimizar = ref([])
  const rutasOptimizadas = ref([])
  const rutasSelected = ref([])

  const updatePedidosSelected = pedidoId => {
    if (!pedidosSelected.value.includes(pedidoId)) {
      pedidosSelected.value.push(pedidoId)
      return
    }
    const index = pedidosSelected.value.indexOf(pedidoId)
    pedidosSelected.value.splice(index, 1)
  }

  const updateRutasSelected = rutaId => {
    if (!rutasSelected.value.includes(rutaId)) {
      rutasSelected.value.push(rutaId)
      return
    }
    const index = rutasSelected.value.indexOf(rutaId)
    rutasSelected.value.splice(index, 1)
  }

  const clientesList = computed(() => {
    const result = clientes.value
    for (let index = 0; index < result.length; index += 1) {
      const c = result[index]
      result[index].key = c.id
      result[index].title = `${c.id} ${c.nombre}`
    }
    return result
  })
  // clientes.value.map(c => ({ key: c.id, title: `${c.id} ${c.nombre}` }))}

  const productosList = computed(() => {
    const result = []
    for (let index = 0; index < productos.value.length; index += 1) {
      const c = productos.value[index]
      result.push({
        id: c.id,
        title: `${c.id} ${c.name}`,
        name: c.name,
      })
      // result[index].value = c.id
      // result[index].key = c.id
      // result[index].title = `${c.id} ${c.name}`
    }
    return result
  })

  const pedidosControlIndex = computed(() => {
    if (!pedidos.value.length || !pedidosControl.value.length) return []
    const result = []
    const dias = ['D-', 'L-', 'M-', 'X-', 'J-', 'V-', 'S-']
    const idDia = new Date().getUTCDay()
    pedidos.value.forEach(pedido => {
      const idx = pedidosControl.value.indexOf(pedido.id) + 1
      const txt = idx ? `${dias[idDia]}${idx} :: ` : ''
      result.push({
        id: pedido.id,
        idx,
        txt,
      })
    })
    return result
  })
  // ------------------------------------------------
  // ZonaFilter - statusFilter
  // ------------------------------------------------
  const zonaFilter = ref(null)
  const zonaOptions = [
    { label: 'Zona 0', value: '0' },
    { label: 'Zona 1', value: '1' },
    { label: 'Zona 2', value: '2' },
  ]
  const statusFilterTextual = computed(() => route.value.meta.view.toLowerCase())
  const statusFilter = computed(() => {
    let result = 0
    switch (statusFilterTextual.value) {
      case 'pending':
        result = 0
        break
      case 'picked':
        result = 1
        break
      case 'routed':
        result = 3
        break
      case 'dispatched':
        result = 7
        break
      case 'delivered':
        result = 15
        break
      default:
        break
    }
    return result
  })

  const fechaDesde = computed(() => store.state.app.fechaDesde)
  const fechaHasta = computed(() => store.state.app.fechaHasta)

  // ------------------------------------------------
  // RutaFilter
  // ------------------------------------------------
  const rutaFilter = ref(null)
  const rutaOptions = computed(() => {
    if (!rutas.value.length) return []
    return rutas.value.map(r => ({ label: `${r.vehiculo_tipo} - ${r.nombre}`, value: r.id }))
  })

  // ------------------------------------------------
  // searchQuery
  // ------------------------------------------------
  const searchQuery = ref('')
  const updateSearchQuery = txt => {
    searchQuery.value = txt
  }

  // ------------------------------------------------
  // Table Options
  // ------------------------------------------------
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const perPage = ref(10)
  const currentPage = ref(1)
  const perPageOptions = ref([10, 25, 50, 100, 500, 1000, 1500, 2000])
  const tableColumns = [
    { key: 'select', label: '', sortable: false },
    { key: 'orden', label: '#', sortable: false },
    { key: 'pedido', label: 'Pedido', sortable: false },
    { key: 'client', label: 'Cliente', sortable: false },
    { key: 'cliente_ruta', label: 'Z', sortable: false },
    { key: 'bultos', label: 'B', sortable: false },
    { key: 'ruta', label: 'Ruta', sortable: false },
    { key: 'status', label: 'Status', sortable: false },
    { key: 'actions' },
  ]

  const sortByRuta = ref('id')
  const isSortDirDescRuta = ref(true)
  const rutasTableColumns = [
    { key: 'select', label: '', sortable: false },
    { key: 'id', label: 'id', sortable: true },
    { key: 'nombre', label: 'Nombre', sortable: true },
    { key: 'vehiculo_tipo', label: 'Vehiculo', sortable: true },
    { key: 'pedidos', label: 'p', sortable: true },
    { key: 'status', label: 'Status', sortable: true },
    { key: 'actions' },
  ]

  // ------------------------------------------------
  // Aux Methods
  // ------------------------------------------------
  // const indexPedidosControl = pedidoId => (pedidosControl.value ? pedidosControl.value.indexOf(pedidoId) + 1 : false)
  // const indexPedidosControl = computed(pedidoId => (pedidosControl.value ? pedidosControl.value.indexOf(pedidoId) + 1 : false))
  // const pintaIndexPedidosControl = computed(pedidoId => {
  //   const dias = ['D-', 'L-', 'M-', 'X-', 'J-', 'V-', 'S-']
  //   const idDia = new Date().getUTCDay()
  //   // const idx = indexPedidosControl(pedidoId).value
  //   const idx = pedidosControl.value ? pedidosControl.value.indexOf(pedidoId) + 1 : false
  //   if (idx) {
  //     return `${dias[idDia]}${idx} :: `
  //   }
  //   return ''
  // })
  const rutaColorIcon = ruta => {
    let result = '#c4c4c4'
    const item = rutas.value.filter(r => r.id === ruta)[0]
    if (item) {
      result = item.color
    }
    return result
  }
  const picking = item => {
    if (!item || item.status > 1 || item.is_picking_completed) return
    pedidoSelect.value = item
    isPickingPedido.value = true
  }
  const selectedByMarker = clienteId => {
    const pedido = pedidos.value.filter(i => i.cliente_id === clienteId)[0]
    if (!pedido) return
    updatePedidosSelected(pedido.id)
    // const index = pedidosSelected.value.indexOf(pedido.id)
    // if (index > -1) pedidosSelected.value.splice(index, 1)
    // else pedidosSelected.value.push(pedido.id)
  }
  const selectRutasAll = valor => {
    rutasSelected.value = []
    if (valor) {
      rutas.value.forEach(i => {
        rutasSelected.value.push(i.id)
      })
    }
  }
  /**
   * Comparación lógica a nivel de bit del status de los pedidos seleccionados.
   * Modifica el valor de pedidosSelected con aquellos pedidos que cumplen la condición.
   * @param {*} bitConpare Bit a comparar: 1, 2, 4, 8
   * @param {*} notCompare Comparación negativa
   * @returns | true | false
   */
  const checkStatusPedidos = (bitConpare, notCompare = false) => {
    const selections = []
    let triger = false
    for (let index = 0; index < pedidosSelected.value.length; index += 1) {
      triger = true
      const pedidoId = pedidosSelected.value[index]
      const pedido = pedidos.value.filter(p => p.id === pedidoId)[0]
      if (pedido) {
        /* eslint-disable no-bitwise */
        let compare = !!(bitConpare & pedido.status) // Comparación positiva
        compare = notCompare ? !compare : compare // Invierte la prueba en caso de comparación negativa
        if (compare) {
          selections.push(pedido)
        }
      }
    }
    pedidosSelected.value = selections
    if (!pedidosSelected.value.length) {
      toast({
        component: ToastificationContent,
        props: {
          title: triger ? 'La selección de pedidos no es válida' : 'Ningún pedido seleccionado',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      },
        {
          position: 'top-center',
        })
      return false
    }
    return true
  }

  const pickingToRutasOff = () => {
    const procede = checkStatusPedidos(8)
    if (!procede) return
    for (let index = 0; index < pedidosSelected.value.length; index += 1) {
      const pedido = pedidosSelected.value[index]
      const pedidoObj = pedidos.value.filter(i => i.id === pedido)[0]
      const payload = {
        pedido,
        ruta: null,
      }
      if (pedidoObj && pedidoObj.ruta) store.dispatch('rutas/peditoToRuta', payload)
    }
  }

  const pickingToRutas = () => {
    const procede = checkStatusPedidos(4, true)
    if (!procede) return
    isPickingPedidosToRutas.value = true
  }

  const pickingVehiculo = item => {
    pedidosSelected.value = []
    if (item && item.id) {
      pedidosSelected.value = [item.id]
    }
    pickingToRutas()
    // consolear(item)
    // if (!item || item.status > 1) return
    // consolear('continuamos...')
    // pedidosSelected.value = [item.id]
    // pickingToRutas()
  }
  const editPedido = p => {
    pedidoSelect.value = p
    isEditPedido.value = true
  }

  const editRuta = p => {
    rutaSelect.value = p
    isEditRuta.value = true
  }
  const addPedido = () => { isAddNewPedidoSidebarActive.value = true }
  const resolveUserStatusVariant = status => {
    const result = {
      txt: 'Unknown',
      color: 'danger',
    }
    switch (status) {
      case 0:
        result.txt = 'Pending'
        result.color = 'secondary'
        break
      case 1:
        result.txt = 'Picked'
        result.color = 'primary'
        break
      case 3:
        result.txt = 'Routed'
        result.color = 'warning'
        break
      case 7:
        result.txt = 'Dispatched'
        result.color = 'info'
        break
      case 15:
        result.txt = 'Delivered'
        result.color = 'success'
        break
      default:
        break
    }
    return result
  }
  const resolveRutaStatusVariant = status => {
    const result = {
      txt: 'Unknown',
      color: 'danger',
    }
    switch (status) {
      case 0:
        result.txt = 'Pending'
        result.color = 'secondary'
        break
      case 1:
        result.txt = 'Creada'
        result.color = 'primary'
        break
      case 3:
        result.txt = 'Routed'
        result.color = 'warning'
        break
      case 7:
        result.txt = 'Dispatched'
        result.color = 'info'
        break
      case 15:
        result.txt = 'Delivered'
        result.color = 'success'
        break
      default:
        break
    }
    return result
  }

  // ------------------------------------------------
  // Pedidos Filters
  // ------------------------------------------------
  const pedidosSeleccionados = computed(() => {
    const result = []
    pedidosSelected.value.forEach(id => {
      const p = pedidos.value.filter(item => item.id === id)[0]
      if (p) result.push(p)
    })
    return result
  })
  const itemsFilterPedidos = computed(() => {
    const queryLowered = searchQuery.value.toLowerCase()
    let result = pedidos.value
    result = pedidos.value.filter(
      pedido =>
        /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
        (pedido.id.toLowerCase().includes(queryLowered) || pedido.cliente_id.toLowerCase().includes(queryLowered) || pedido.cliente_nombre.toLowerCase().includes(queryLowered) || pedido.cliente_dir_literal.toLowerCase().includes(queryLowered)) && pedido.cliente_ruta.toString() === (zonaFilter?.value || pedido.cliente_ruta.toString()) && pedido.status.toString() === (statusFilter?.value.toString() || pedido.status.toString()),
    )
    if (rutaFilter.value) {
      result = result.filter(
        pedido => (pedido.ruta && pedido.ruta.toString() === (rutaFilter.value.toString() || pedido.ruta.toString())),
      )
    }

    // const sortedData = result.sort(sortCompare(sortBy.value))
    // if (isSortDirDesc.value) sortedData.reverse()
    return result
  })

  const itemsFiltereds = computed(() => {
    const result = itemsFilterPedidos.value
    if (!result.length) {
      return {
        pedidos: [],
        total: 0,
        totalBultos: 0,
      }
    }
    const pedidosResult = result.map((pedido, i) => {
      const ped = pedido
      // const timestampSelected = ped.delivered_at || ped.fecha
      ped.orden = i
      ped.bultos = ped.productos.map(p => p.uds).reduce(reducer)
      ped.fechaIso = fechaHoraCortas(ped.fecha)
      ped.fechaEntrega = ped.delivered_at ? fechaHoraCortas(ped.delivered_at) : ''
      return ped
    })
    return {
      pedidos: paginateArray(pedidosResult, perPage.value, currentPage.value),
      total: pedidosResult.length,
      totalBultos: pedidosResult.map(p => p.bultos).reduce(reducer),
    }
  })

  const itemsMostrarOrden = computed(() => {
    if (pedidosPreOrdenados.value.length) {
      const result = pedidosPreOrdenados.value
      return {
        pedidos: result,
        total: result.length,
        totalBultos: itemsFiltereds.value.totalBultos,
      }
    }
    if (itemsMostrarOrdenSync.value) return itemsMostrarOrdenSync.value
    return itemsFiltereds.value
  })

  const itemsToMap = computed(() => {
    // if (mapView.value.length) return mapView.value
    // const data = itemsMostrarOrdenSync.value ? itemsMostrarOrdenSync.value.pedidos : itemsMostrarOrden.value.pedidos
    const data = itemsMostrarOrden.value.pedidos
    if (!data.length) return []
    const result = data.map(p => ({
      id: p.cliente_id,
      orden: p.orden,
      lat: p.cliente_lat,
      lng: p.cliente_lng,
      nombre: p.cliente_nombre,
      ruta: p.cliente_ruta,
    }))

    return result
  })

  const selectedToMarkers = computed(() => {
    const result = []
    if (pedidosSelected.value.length) {
      pedidosSelected.value.forEach(p => {
        const clienteId = pedidos.value.filter(i => i.id === p).map(f => f.cliente_id)[0]
        result.push(clienteId)
      })
    }
    return result
  })

  const pedidosRutas = computed(() => {
    let result = []
    const pedidosCalc = pedidos.value.filter(p => p.ruta)
    if (pedidosCalc.length) result = pedidosCalc.map(p => ({ id: p.id, ruta: p.ruta }))
    return result
  })

  const rutasGroupPedidos = computed(() => {
    // Grupo de pedidos por cada ruta
    // const result = this.rutas.map(r => ({
    //   [r.id]: [],
    // }))
    const result = {}
    rutas.value.forEach(r => {
      result[r.id] = []
    })
    const pedidosCalc = pedidosRutas.value
    if (pedidosCalc.length) {
      for (let index = 0; index < rutas.value.length; index += 1) {
        const ruta = rutas.value[index]
        const pedidosR = pedidosCalc.filter(p => p.ruta === ruta.id)
        for (let i = 0; i < pedidosR.length; i += 1) {
          const pedido = pedidosR[i]
          result[ruta.id].push(pedido.id)
        }
      }
    }
    return result
  })

  const rutasSelectedConPedidos = computed(() => {
    const result = []
    rutasSelected.value.forEach(ruta => {
      if (rutasGroupPedidos.value[ruta].length) {
        result.push(ruta)
      }
    })
    return result
  })

  const resolveRutaPedidos = ruta => {
    const value = rutasGroupPedidos.value[ruta].length
    const result = {
      color: 'secondary',
      value,
    }
    if (value) {
      result.color = 'success'
    }
    return result
  }

  // ------------------------------------------------
  // Aux Methods 2
  // ------------------------------------------------
  const selectPedidosAll = valor => {
    pedidosSelected.value = []
    if (valor) {
      itemsMostrarOrden.value.pedidos.forEach(i => {
        pedidosSelected.value.push(i.id)
      })
    }
  }

  const updateMapView = datos => {
    mapView.value = datos
  }

  const updateMostrarOrden = datos => {
    itemsMostrarOrdenSync.value = datos
  }

  const updateRutaFilter = valor => {
    rutaFilter.value = valor
  }

  const updateZonaFilter = valor => {
    zonaFilter.value = valor
  }

  const tratarPreruta = (items, preRuta) => {
    // const pedidos = this.itemsFilterPedidos
    const orden = preRuta.waypoint_order
    overviewPath.value = preRuta.overview_path
    // consolear(orden)
    const result = []
    let count = 0
    orden.forEach(index => {
      const pedido = items[index]
      pedido.orden = count
      result.push(pedido)
      count += 1
    })
    pedidosPreOrdenados.value = result
    // this.sortBy = 'id'
    // this.isSortDirDesc = true
  }

  const preCalculaRutas = async () => {
    consolear('pre-calculando')
    calculandoRuta.value = true
    pedidosPreOrdenados.value = []
    const items = pedidosSeleccionados.value // itemsFilterPedidos.value
    pedidosOrdenAnterior.value = items
    const waypts = items.map(p => ({
      location: p.cliente_dir_literal,
      stopover: true,
    }))
    const datos = {
      start: DIRALMACEN,
      end: DIRALMACEN,
      waypts,
    }
    // consolear(datos)
    const result = await directions(datos)
    calculandoRuta.value = false
    tratarPreruta(items, JSON.parse(JSON.stringify(result)))

    if (!items.length) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Ningún pedido seleccionado',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      },
        {
          position: 'top-center',
        })
    } else {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Ruta Pre-Calculada',
          icon: 'CheckIcon',
          variant: 'success',
        },
      },
        {
          position: 'top-center',
        })
    }
    // consolear(JSON.stringify(result))
    // consolear(result.waypoint_order)
    // [0, 3, 6, 2, 4, 5, 7, 1]
    // .then(result => {
    // })
  }

  const limpiaRutaLink = txt => {
    const regex = /\s/ig
    const regex2 = /,\+España/ig
    const regex3 = /\//ig // Elimina el caracter /
    return txt.replace(regex3, '.').replace(regex, '+').replace(regex2, '')
  }

  const tratarRutaOptimizada = () => {
    const regex = /\s/ig
    const regex2 = /,\+España/ig
    const regex3 = /\//ig // Elimina el caracter /
    const urlMap = 'https://www.google.com/maps/dir/'
    // const result = []
    rutasOptimizadas.value.forEach(ruta => {
      const { rutaId, rutaCalculada, ordenPrevio } = ruta
      consolear(rutaId)
      const { legs, waypoint_order, overview_path } = rutaCalculada
      consolear(legs)
      const distancias = []
      const duracion = []
      let distanciaTotal = 0 // metros
      let duracionTotal = 0 // segundos
      legs.forEach(l => {
        distancias.push(l.distance)
        duracion.push(l.duration)
        distanciaTotal += l.distance.value
        duracionTotal += l.duration.value
      })
      const ordenPedidos = []
      waypoint_order.forEach(w => {
        const pedidoId = ordenPrevio[w].id
        const pedidoDetails = pedidos.value.filter(p => p.id === pedidoId)[0]
        // const lnk1 = limpiaRutaLink(pedidoDetails.cliente_dir_literal)
        pedidoDetails.mapLink = `${urlMap}/${limpiaRutaLink(pedidoDetails.cliente_dir_literal)}`
        // .map(s => ({
        //   id: pedidoId,
        //   cliente_nombre: s.cliente_nombre,
        //   direccion: s.cliente_dir_calle,
        //   numero: s.cliente_dir_numero,
        //   cp: s.cliente_dir_cp,
        //   dir_literal: s.cliente_dir_literal,
        // }))
        ordenPedidos.push(pedidoDetails)
      })
      const address = legs.map(l => l.start_address.replace(regex3, '.'))
      address.push(DIRALMACEN)
      const link = urlMap + address.join('/').replace(regex, '+').replace(regex2, '')
      const datos = {
        id: rutaId,
        status: 1,
        calculated: {
          address,
          ordenPedidos,
          distancias,
          distanciaTotal,
          duracion,
          duracionTotal,
          link,
          overview_path, // Guardar path para usar en mapas
        },
      }
      store.dispatch('rutas/updateRuta', datos)
      // consolear(datos)
    })
    toast({
      component: ToastificationContent,
      props: {
        title: 'Ruta/s Optimizada/s',
        icon: 'CheckIcon',
        variant: 'success',
      },
    },
      {
        position: 'top-center',
      })
  }

  const calculaRutas = async () => {
    if (!rutasSelectedConPedidos.value.length) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Las rutas seleccionadas no tienen pedidos',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      },
        {
          position: 'top-center',
        })
      return
    }
    consolear('calculando...')
    // Inicializa variables
    calculandoRuta.value = true
    rutasOptimizadas.value = []
    rutasSelecOptimizar.value = []
    // const rutasParaCalcular = []
    rutasSelectedConPedidos.value.forEach(ruta => {
      consolear(ruta)
      const items = rutasGroupPedidos.value[ruta]
      // const waypts = this.items.filter(p)
      const filtrados = pedidos.value.filter(p => items.includes(p.id))
      const ordenPrevio = filtrados.map((f, i) => ({
        orden: i,
        id: f.id,
        location: f.cliente_dir_literal,
      }))
      consolear(ordenPrevio)
      const waypts = filtrados.map(x => ({
        location: x.cliente_dir_literal,
        stopover: true,
      }))
      rutasSelecOptimizar.value.push({
        ruta,
        pedidos: items,
        waypts,
        ordenPrevio,
      })
    })
    // this.rutasSelecOptimizar = rutasParaCalcular
    for (let index = 0; index < rutasSelecOptimizar.value.length; index += 1) {
      const element = rutasSelecOptimizar.value[index]
      const datos = {
        start: DIRALMACEN,
        end: DIRALMACEN,
        waypts: element.waypts,
      }
      // eslint-disable-next-line no-await-in-loop
      const result = await directions(datos)
      rutasOptimizadas.value.push({
        rutaId: element.ruta,
        ordenPrevio: element.ordenPrevio,
        rutaCalculada: JSON.parse(JSON.stringify(result)),
      })
    }
    // consolear(this.rutasOptimizadas)
    tratarRutaOptimizada()
    calculandoRuta.value = false
  }

  const formatJson = (filterVal, jsonData) => jsonData.map(v => filterVal.map(j => v[j]))

  const exportToExcel = () => {
    // consolear(rutas.value)
    const productosLista = productos.value.map(i => i.id)
    // consolear(productosLista)
    const list = itemsFilterPedidos.value
    // consolear(list)
    if (!list.length) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Sin datos para exportar',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      },
        {
          position: 'top-center',
        })
      return
    }
    const listado = []
    list.forEach(item => {
      const rutaItem = rutas.value.filter(i => i.id === item.ruta)[0]
      listado.push(item)
      listado[listado.length - 1].rutaDetalle = rutaItem
    })
    const { headers, results } = parserPedidos(listado, productosLista)
    import('@/libs/Export2Excel').then(excel => {
      const data = formatJson(headers, results)
      excel.export_json_to_excel({
        header: headers,
        data,
        filename: 'export',
        autoWidth: true,
        bookType: 'xlsx',
      })
    })
  }

  const exportRutasToExcel = () => {
    const list = rutas.value
    if (!list.length) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Sin datos para exportar',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      },
        {
          position: 'top-center',
        })
      return
    }
    const { headers, results } = parserRutas(list)
    import('@/libs/Export2Excel').then(excel => {
      const data = formatJson(headers, results)
      excel.export_json_to_excel({
        header: headers,
        data,
        filename: 'rutas',
        autoWidth: true,
        bookType: 'xlsx',
      })
    })
  }

  const test = async () => {
    // Busca dirección por texto:
    // const result = await places('calle serrano')

    // Geolocaliza por placeId (devuelto por el anterior...)
    const result = await geo('EipDYWxsZSBTZXJyYW5vLCBBbGljYW50ZSAoQWxhY2FudCksIEVzcGHDsWEiLiosChQKEgkJhBaPTjZiDREHS1Wc62E8gRIUChIJS6udO9o1Yg0R44ELrHKofR0')
    consolear(result)
  }

  const searchPlaces = async () => {
    // Busca dirección por texto:
    const result = await places('calle serrano')
    return result
  }

  const deleteRuta = id => store.dispatch('rutas/deleteRuta', id)

  // *===============================================---*
  // *--------- TICKET ---------------------------------*
  // *===============================================---*
  const ticketPedido = ref({})

  const getTicketPedido = id => {
    const tk = pedidos.value.filter(p => p.id === id)[0]
    if (tk) {
      ticketPedido.value = tk
      return
    }
    store.dispatch('pedidos/getPedidoById', id)
      .then(response => {
        ticketPedido.value = response
      })
      .catch(error => {
        consolear(error)
        ticketPedido.value = undefined
      })
  }

  const splitedPedido = pedidoId => pedidoId.split('R')[1]
  const fechaIso = fecha => fechaHoraCortas(fecha)
  const checkPedido = pedidoId => {
    // consolear('Chequeando pedido: ', pedidoId)
    const pedido = JSON.parse(JSON.stringify(pedidosPicking.value.filter(p => p.id === pedidoId)[0]))
    const result = []
    if (pedido && pedido.productos) {
      pedido.productos.forEach(producto => {
        const { uds, lines } = producto
        const added = lines ? lines.length : 0
        const dif = uds - added
        if (!lines || lines.length < uds) {
          result.push(producto)
          result[result.length - 1].name = productos.value.filter(p => p.id === producto.ref)[0].name
          result[result.length - 1].udsPending = dif
          result[result.length - 1].udsAdded = added
        }
      })
    }
    return result
  }
  const listaProductosPendientes = ref(null)
  const updatePedido = pedidoId => {
    listaProductosPendientes.value = checkPedido(pedidoId)
  }
  const disableAdding = ref(false) // desactuva el botón de agregar productos hasta actualizar los datos
  const addProducto = async (productoId, pedidoId) => {
    disableAdding.value = true
    consolear(`addProducto ${productoId} to ${pedidoId}`)
    const pedidoDB = pedidosPicking.value.filter(p => p.id === pedidoId)[0]
    consolear('********************')
    consolear(pedidoDB)
    consolear('***************')
    if (!pedidoDB) {
      console.error('Pedido no encontrado')
      return
    }
    const pedido = JSON.parse(JSON.stringify(pedidoDB))
    const productosDB = pedido.productos
    if (!productosDB) {
      console.error('Pedido Sin Productos')
      return
    }
    let update = false
    let isCompletedPicking = true
    const products = JSON.parse(JSON.stringify(productosDB))
    for (let index = 0; index < products.length; index += 1) {
      const producto = products[index]
      const { lines, uds } = producto
      const totalCargados = lines ? lines.length : 0
      if (producto.ref === productoId) {
        if (totalCargados < uds) {
          update = true
          if (totalCargados < 1) products[index].lines = []
          products[index].lines.push({
            added: 1,
            fecha: '',
            lote: 'aqui',
            id: `${productoId}-${index}-${totalCargados}`,
          })
        }
        if (totalCargados + 1 < uds) {
          isCompletedPicking = false
        }
      } else if (totalCargados < uds) {
        // Verifica si faltan productos
        isCompletedPicking = false
      }
      // const producto = productos.filter(p => p.ref === productoId)
      // const productoCheck = checkPedido(pedidoId)
      // const update = await updatePedido()
    }
    consolear(`update: ${update}`)
    if (update) {
      pedido.productos = products
      // Validar si el picking está o no completado
      if (isCompletedPicking) {
        consolear('El pedido está completo')
        pedido.is_picking_completed = true
        pedido.status = 3
      }
      await store.dispatch('pedidos/updatePedido', pedido)
      disableAdding.value = false
      updatePedido(pedidoId)
      // listaProductosPendientes.value = checkPedido(pedidoId)
      consolear(pedido)
    }
    // consolear(products)
    // consolear(pedidoDB)
  }

  return {
    // UI
    isEtiquetasSelectionInit, // Modal de selección de comienzo de impresión etiquetas
    isPickingPedido,
    isEditPedido,
    isPickingVehiculo,
    isPickingPedidosToRutas,
    pedidoSelect,
    isAddNewPedidoSidebarActive,
    isAddNewClienteSidebarActive,
    isAddNewRutaSidebarActive,
    calculandoRuta,
    rutaSelect,
    isEditRuta,

    // Datos
    productos,
    productosList,
    clientes,
    clientesList,
    pedidos,
    pedidosControl,
    rutas,
    vehiculos,
    pedidosPreOrdenados,
    pedidosOrdenAnterior,
    overviewPath,
    pedidosSelected,
    itemsMostrarOrden,
    itemsToMap,
    selectedToMarkers,
    rutasSelecOptimizar,
    rutasOptimizadas,
    updateRutasSelected,
    rutasGroupPedidos,
    pedidosControlIndex,
    // Datos Ticket
    ticketPedido,

    // Filtros
    zonaFilter,
    zonaOptions,
    rutaFilter,
    rutaOptions,
    searchQuery,
    updateSearchQuery,
    statusFilter,
    fechaDesde,
    fechaHasta,

    // Tables
    perPage,
    currentPage,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    tableColumns,
    sortByRuta,
    isSortDirDescRuta,
    rutasSelected,
    rutasTableColumns,

    // methods
    fetchClientes,
    fetchPedidos,
    fetchProductos,
    fetchRutas,
    fetchVehiculos,
    rutaColorIcon,
    picking,
    selectedByMarker,
    selectPedidosAll,
    selectRutasAll,
    pickingToRutas,
    pickingToRutasOff,
    pickingVehiculo,
    editPedido,
    resolveUserStatusVariant,
    resolveRutaStatusVariant,
    updatePedidosSelected,
    addPedido,
    resolveRutaPedidos,
    updateMapView,
    updateMostrarOrden,
    updateRutaFilter,
    updateZonaFilter,
    preCalculaRutas,
    calculaRutas,
    exportToExcel,
    exportRutasToExcel,
    searchPlaces,
    test,
    getTicketPedido,
    editRuta,
    deleteRuta,
    pedidosPicking,
    splitedPedido,
    fechaIso,
    checkPedido,
    listaProductosPendientes,
    disableAdding,
    addProducto,
    updatePedido,
  }
}
